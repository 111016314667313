


































































import { Component, Vue, Watch } from "vue-property-decorator";
import { KEYS } from "@/plugins/i18n";
import { PaymentsDetailsDialog, PaymentsListDialog } from "@/store/namespaces";
import PaymentDto from "@/dtos/PaymentDto";
import { AxiosError } from "axios";
import { paymentsService } from "@/rest/payments.service";
import { CustomFilters } from "@/plugins/filters";

@Component
export default class PaymentDetailsComponent extends Vue {
  private paymentDate_datePicker_model = "";

  private yearsForSeasonStart: number[] = [];

  @PaymentsDetailsDialog.Getter
  private isPaymentDetailsDialogShowing!: boolean;
  @PaymentsDetailsDialog.Mutation
  private hidePaymentDetailsDialog!: () => void;
  @PaymentsDetailsDialog.Getter
  private paymentDetailsDialogDto!: PaymentDto | null;

  @PaymentsListDialog.Getter
  private PaymentsListDialogPlayerId!: string | null;

  private seasonYear = 0;

  private heading = this.$t(KEYS.payments.add_payment).toString();
  private s_save = this.$t(KEYS.common.save).toString();
  private s_cancel = this.$t(KEYS.common.cancel).toString();
  private s_valueRequired = this.$t(KEYS.error.value_required).toString();
  private l_paymentDate = this.$t(KEYS.payments.payment_date).toString();
  private l_saison = this.$t(KEYS.payments.saison).toString();
  private l_seasonStart = this.$t(KEYS.payments.saison_start).toString();
  private l_saisonEnd = this.$t(KEYS.payments.saison_end).toString();

  private isValid = false;
  private errorMessage = "";

  private dateForLabel(date: Date): string {
    return CustomFilters.date(date);
  }

  @Watch("seasonYear")
  private setSeasonStartYear(newValue: number) {
    if (
      !this.paymentDetailsDialogDto?.periodStart ||
      !this.paymentDetailsDialogDto?.periodEnd
    ) {
      return;
    }

    const newStartDate =
      this.paymentDetailsDialogDto.periodStart.setFullYear(newValue);

    this.paymentDetailsDialogDto.periodStart = new Date(newStartDate);
  }

  @Watch("isPaymentDetailsDialogShowing")
  private isPaymentDetailsDialogShowing_change(newVal: boolean) {
    if (newVal) {
      this.errorMessage = "";
      this.setDefaultSeasonYear();
    }
  }

  created(): void {
    const currentYear = new Date().getFullYear();
    for (let year = currentYear - 5; year <= currentYear + 100; year++) {
      this.yearsForSeasonStart.push(year);
    }

    this.setDefaultSeasonYear();
  }

  private setDefaultSeasonYear() {
    this.seasonYear =
      this.paymentDetailsDialogDto?.periodStart.getFullYear() ||
      new Date().getFullYear();
  }

  private async submit() {
    this.errorMessage = "";
    try {
      await paymentsService.createPayment(
        this.paymentDetailsDialogDto,
        this.PaymentsListDialogPlayerId || ""
      );
      this.hide();
    } catch (e) {
      const axiosError = e as AxiosError;
      if (axiosError.response?.status === 409) {
        this.errorMessage = this.$t(
          KEYS.error.already_payed_in_that_period
        ).toString();
      } else {
        this.errorMessage = axiosError.response?.data.toString();
      }
    }
  }

  private hide() {
    this.errorMessage = "";
    this.hidePaymentDetailsDialog();
  }
}
