import VueI18n from "vue-i18n";
import Vue from "vue";

Vue.use(VueI18n);

export const KEYS = {
  common: {
    create: "common.create",
    login: "common.login",
    clubs: "common.clubs",
    club: "common.club",
    members: "common.members",
    name: "common.name",
    search: "common.search",
    edit: "common.edit",
    cancel: "common.cancel",
    save: "common.save",
    member: "common.member",
    oedgv: "common.oedgv",
    oedgv_members_only: "common.oedgv_members_only",
    settings: "common.settings",
    city: "common.city",
    federalState: "common.federalState",
    download: "common.download",
    yes: "common.yes",
    no: "common.no",
    columns: "common.columns",
    ok: "common.ok",
    are_you_sure: "common.are_you_sure",
    info: "common.info",
    generated: "common.generated",
    youth_members_only: "common.youth_members_only",
    hasPaid: "common.hasPaid",
  },
  users: {
    dachverbandusers: "users.dachverbandusers",
    dachverbanduser: "users.dachverbanduser",
    tournamentManager: "users.tournamentManagers",
    users: "users.users",
    user: "users.user",
    clubManagers: "users.clubManagers",
    username: "users.username",
    password: "users.password",
    password_repeat: "users.password_repeat",
    passwordRequired: "users.password_required",
    usernameRequired: "users.password_required",
    passwords_dont_match: "users.passwords_dont_match",
    type_of_user: "users.type_of_user",
    email: "users.email",
    change_password: "users.change_password",
    current_password: "users.current_password",
    lockUser: "users.lock_user",
    unlockUser: "users.unlock_user",
  },
  headings: {
    create_dachverbanduser: "headings.create_dachverbanduser",
    create_club: "headings.create_club",
    edit_club: "headings.edit_club",
    create_user: "headings.create_user",
    edit_user: "headings.edit_user",
    reset_password: "headings.reset_password",
    create_player: "headings.create_player",
    edit_player: "headings.edit_player",
  },
  error: {
    invalidUsernamePassword: "error.invalidUsernamePassword",
    value_required: "error.value_required",
    username_already_exists: "error.username_already_exists",
    email_already_exists: "error.name_already_exists",
    already_payed_in_that_period: "error.already_payed_in_that_period",
    user_is_locked: "error.user_is_locked",
    club_has_members: "error.club_has_members",
    invalid_value: "error.invalid_value",
    invalid_file_uploaded: "error.invalid_file_uploaded",
    club_name_exists: "error.club_name_exists",
    general_error: "error.general_error",
    player_exists: "error.player_exists",
    oedgv_number_exists: "error.oedgv_number_exists",
    pdga_exists: "error.pdga_exists",
    invalid_row: "error.invalid_row",
    player_number_required: "error.player_number_required",
    player_not_found: "error.player_not_found",
    You_do_not_have_permission_to_perform_this_action:
      "error.You_do_not_have_permission_to_perform_this_action",
  },
  players: {
    playerNumber: "players.playerNumber",
    pdgaNumber: "players.pdgaNumber",
    firstName: "players.firstName",
    lastName: "players.lastName",
    email: "players.email",
    primaryClub: "players.primaryClub",
    gender: "players.gender",
    street: "players.street",
    streetNumber: "players.streetNumber",
    zipCode: "players.zipCode",
    city: "players.city",
    phone: "players.phone",
    country: "players.country",
    instructorLevel: "players.instructorLevel",
    wannaBeContactedAsInstructor: "players.wannaBeContactedAsInstructor",
    youthPlayer: "players.youthPlayer",
    oedgv_membership: "players.oedgv_membership",
    birthday: "players.birthday",
    exportPlayers: "players.exportPlayers",
    deletePlayer: "players.deletePlayer",
    importPlayers: "players.importPlayers",
  },
  instructorLevels: {
    uebungsleiter: "instructorLevels.uebungsleiter",
    instruktor: "instructorLevels.instruktor",
    trainer: "instructorLevels.trainer",
    none: "instructorLevels.none",
  },
  genders: {
    male: "genders.male",
    female: "genders.female",
    diverse: "genders.diverse",
  },
  settings: {
    saisonBegin: "settings.saisonBegin",
  },
  payments: {
    payments: "payments.payments",
    payment_date: "payments.payment_date",
    add_payment: "payments.add_payment",
    saison_start: "payments.saison_start",
    saison_end: "payments.saison_end",
    saison: "payments.saison",
    paid: "payments.paid",
    checkPayments: "payments.checkPayments",
    importPayments: "payments.importPayments",
  },
  oedgvMembership: {
    not_a_mamber: "oedgvMembership.not_a_member",
    active_till: "oedgvMembership.active_till",
    ended: "oedgvMembership.ended",
  },
  federateStates: {
    unknown: "federateStates.unknown",
    Vienna: "federateStates.Vienna",
    LowerAustria: "federateStates.LowerAustria",
    UpperAustria: "federateStates.UpperAustria",
    Burgenland: "federateStates.Burgenland",
    Styria: "federateStates.Styria",
    Salzburg: "federateStates.Salzburg",
    Carinthia: "federateStates.Carinthia",
    Tyrol: "federateStates.Tyrol",
    Vorarlberg: "federateStates.Vorarlberg",
  },
  clubs: {
    deleteClub: "clubs.deleteClub",
    allow_edit_members: "clubs.allow_edit_members",
  },
};

const messages = {
  de: {
    common: {
      create: "anlegen",
      login: "Login",
      clubs: "Vereine",
      club: "Verein",
      members: "Mitglieder",
      name: "Name",
      search: "suchen",
      edit: "bearbeiten",
      cancel: "abbrechen",
      save: "speichern",
      member: "Mitglied",
      oedgv: "ÖDGV",
      oedgv_members_only: `nur @:${KEYS.common.oedgv} Mitglieder`,
      settings: "Einstellungen",
      city: "Ort",
      federalState: "Bundesland",
      download: "Herunterladen",
      yes: "Ja",
      no: "Nein",
      columns: "Spalten",
      ok: "ok",
      are_you_sure: "Sind Sie sicher?",
      info: "Info",
      generated: "generiert",
      youth_members_only: "nur Jugendliche",
      hasPaid: "hat bezahlt",
    },
    users: {
      dachverbandusers: "ÖDGV",
      dachverbanduser: "ÖDGV",
      clubManagers: "Vereine",
      tournamentManagers: "Turniere",
      username: "Username",
      password: "Passwort",
      password_repeat: "Passwort wiederholen",
      password_required: "Passwort eingeben",
      username_required: "Username eingeben",
      passwords_dont_match: "Passwort stimmt nicht überein",
      users: "User",
      user: "User",
      type_of_user: "Userart",
      email: "E-Mail",
      change_password: "Passwort ändern",
      current_password: "Aktuelles Passwort",
      unlock_user: "Benutzer entsprerren",
      lock_user: "Benutzer sperren",
    },
    genders: {
      male: "männlich",
      female: "weiblich",
      diverse: "divers",
    },
    players: {
      playerNumber: `@:${KEYS.common.oedgv} #`,
      pdgaNumber: "PDGA #",
      firstName: "Vorname",
      lastName: "Nachname",
      email: "E-Mail",
      primaryClub: "Verein",
      gender: "Geschlecht",
      birthday: "Geburtstag",
      street: "Straße",
      streetNumber: "Hausnummer",
      zipCode: "PLZ",
      city: "Ort",
      phone: "Telefonnummer",
      country: "Land",
      instructorLevel: "Instruktor Level",
      wannaBeContactedAsInstructor:
        "Der Spieler möchte kontaktiert werden und stimmt zu, dass seine E-Mail Adresse und sein Name hierfür veröffentlicht werden",
      youthPlayer: "Jugendlicher",
      oedgv_membership: `@:${KEYS.common.oedgv} Mitgliedschaft`,
      exportPlayers: `@:${KEYS.common.members} exportieren`,
      deletePlayer: `@:${KEYS.common.member} löschen`,
      importPlayers: `@:${KEYS.common.members} importieren`,
    },
    instructorLevels: {
      none: "(Keine Auswahl)",
      uebungsleiter: "Übungsleiter",
      instruktor: "Instruktor",
      trainer: "Trainer",
    },
    headings: {
      create_dachverbanduser: `@:${KEYS.users.dachverbandusers} @:${KEYS.common.create}`,
      create_club: `@:${KEYS.common.club} @:${KEYS.common.create}`,
      edit_club: `@:${KEYS.common.club} @:${KEYS.common.edit}`,
      create_user: `@:${KEYS.users.user} @:${KEYS.common.create}`,
      create_player: `@:${KEYS.common.member} @:${KEYS.common.create}`,
      edit_player: `@:${KEYS.common.member} @:${KEYS.common.edit}`,
      edit_user: `@:${KEYS.users.user} @:${KEYS.common.edit}`,
      reset_password: "Passwort zurücksetzen",
    },
    error: {
      invalidUsernamePassword: `Falsche @:${KEYS.users.username} / @:${KEYS.users.password} Kombination`,
      value_required: "Bitte einen Wert angeben",
      username_already_exists: "Username bereits vergeben",
      email_already_exists: "Email existiert bereits",
      already_payed_in_that_period: "Zahlung in der Saison bereits vorhanden",
      user_is_locked: "User ist gesperrt",
      club_has_members: "Verein hat Mitglieder",
      invalid_value: "Ungüliger Wert",
      invalid_file_uploaded: "Ungültiges File hochgeladen",
      club_name_exists: "Vereinsname existiert bereits",
      general_error: "Ein allgemeiner Feher ist aufgetreten",
      player_exists: `@:${KEYS.common.member} existiert bereits`,
      oedgv_number_exists: `@:${KEYS.players.playerNumber} existiert bereits`,
      pdga_exists: `@:${KEYS.players.pdgaNumber} existiert bereits`,
      invalid_row: "Ungülitge Zeile",
      player_number_required: `@:${KEYS.players.playerNumber} angeben`,
      player_not_found: `@:${KEYS.common.member} nicht gefunden`,
      You_do_not_have_permission_to_perform_this_action:
        "Sie sind zu dieser Aktion nicht berechtigt",
    },
    settings: { saisonBegin: "Saisonbeginn" },
    payments: {
      payments: "Zahlungen",
      payment_date: "Zahlungsdatum",
      add_payment: "Zahlung hinzufügen",
      saison_start: "Saisonbeginn",
      saison_end: "Saisonende",
      saison: "Saison",
      paid: "Bezahlt",
      checkPayments: "Zahlungen prüfen",
      importPayments: "Zahlungen importieren",
    },
    oedgvMembership: {
      not_a_member: "Kein Mitglied",
      active_till: "Aktiv bis",
      ended: "Beendet",
    },
    federateStates: {
      Vienna: "Wien",
      LowerAustria: "Niederösterreich",
      UpperAustria: "Oberösterreich",
      Burgenland: "Burgenland",
      Styria: "Steiermark",
      Salzburg: "Salzburg",
      Carinthia: "Kärnten",
      Tyrol: "Tirol",
      Vorarlberg: "Vorarlberg",
    },
    clubs: {
      deleteClub: "Verein löschen",
      allow_edit_members: "Mitglieder können bearbeitet werden",
    },
  },
  en: {
    common: {
      create: "create",
      login: "Login",
      clubs: "Clubs",
      club: "Club",
      members: "Members",
      name: "Name",
      search: "search",
      edit: "edit",
      cancel: "cancel",
      save: "save",
      member: "Member",
      oedgv: "ÖDGV",
      oedgv_members_only: `only @:${KEYS.common.oedgv} members`,
      settings: "settings",
      city: "City",
      federalState: "Federal State",
      download: "Download",
      yes: "Yes",
      no: "No",
      columns: "Columns",
      ok: "ok",
      are_you_sure: "Are you sure?",
      info: "Info",
      generated: "generated",
      youth_members_only: "only Youth Player",
      hasPaid: "has paid",
    },
    users: {
      dachverbandusers: "ÖDGV",
      dachverbanduser: "ÖDGV",
      clubManagers: "Club managers",
      tournamentManagers: "Tournament Managers",
      username: "Username",
      password: "Password",
      password_repeat: "Repeat Password",
      password_required: "Password required",
      username_required: "Username required",
      passwords_dont_match: "Password doesn't match",
      users: "Users",
      user: "User",
      type_of_user: "Type of user",
      email: "Email",
      street: "Street",
      streetNumber: "Street Number",
      change_password: "change password",
      current_password: "current password",
      unlock_user: "Unlock user",
      lock_user: "Lock user",
    },
    genders: {
      male: "male",
      female: "female",
      diverse: "diverser",
    },
    players: {
      playerNumber: `@:${KEYS.common.oedgv} #`,
      pdgaNumber: "PDGA #",
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      primaryClub: "Club",
      gender: "Gender",
      zipCode: "Zip Code",
      city: "City",
      phone: "Mobile",
      country: "Land",
      instructorLevel: "Instructor Level",
      wannaBeContactedAsInstructor:
        "The player wishes to be contacted and agrees that their email address and name are published for this purpose",
      youthPlayer: "youth",
      oedgv_membership: `@:${KEYS.common.oedgv} membership`,
      birthday: "Birthday",
      exportPlayers: `Export ${KEYS.common.member}`,
      deletePlayer: `@:Delete ${KEYS.common.member}`,
      importPlayers: `@:Import ${KEYS.common.members}`,
    },
    instructorLevels: {
      none: "(None)",
      uebungsleiter: "exercise instructor",
      instruktor: "instructor",
      trainer: "trainer",
    },
    headings: {
      create_dachverbanduser: `@:${KEYS.common.create} @:${KEYS.users.dachverbandusers}`,
      create_club: `@:${KEYS.common.create} @:${KEYS.common.club}`,
      edit_club: `@:${KEYS.common.edit} @:${KEYS.common.club}`,
      edit_user: `@:${KEYS.common.edit} @:${KEYS.users.user}`,
      reset_password: "Reset password",
    },
    error: {
      invalidUsernamePassword: `Invalid @:${KEYS.users.username}/@:${KEYS.users.password} combination`,
      value_required: "Required",
      username_already_exists: "Username already exists",
      email_already_exists: "Email already exists",
      already_payed_in_that_period: "Payment in this season already exists",
      user_is_locked: "User is locked",
      club_has_members: "Club has members",
      invalid_value: "Invalid value",
      invalid_file_uploaded: "Invalid file uploaded",
      club_name_exists: "Club name already exists",
      general_error: "A general error occurred",
      player_exists: `@:${KEYS.common.member} already exists`,
      oedgv_number_exists: `@:${KEYS.players.playerNumber} already exists`,
      pdga_exists: `@:${KEYS.players.pdgaNumber} already exists`,
      invalid_row: "Invalid row",
      player_number_required: `@:${KEYS.players.playerNumber} required`,
      player_not_found: `@:${KEYS.common.member} not found`,
      You_do_not_have_permission_to_perform_this_action:
        "You do not have permission to perform this action",
    },
    settings: { saisonBegin: "Season start" },
    payments: {
      payments: "Payments",
      payment_date: "Payment date",
      add_payment: "Add Payment",
      saison_start: "Season beginn",
      saison_end: "Season end",
      saison: "Season",
      paid: "Paid",
      checkPayments: "Check payments",
      importPayments: "Import payments",
    },
    oedgvMembership: {
      not_a_member: "Mot a member",
      active_till: "Active till",
      ended: "Ended",
    },
    federateStates: {
      Vienna: "Vienna",
      LowerAustria: "Lower Austria",
      UpperAustria: "Upper Austria",
      Burgenland: "Burgenland",
      Styria: "Styria",
      Salzburg: "Salzburg",
      Carinthia: "Carinthia",
      Tyrol: "Tirol",
      Vorarlberg: "Vorarlberg",
    },
    clubs: {
      deleteClub: "Delete Club",
      allow_edit_members: "Members can be edited",
    },
  },
};

const i18n = new VueI18n({
  locale: "de",
  fallbackLocale: "en",
  messages,
});

export default i18n;
