
















































































































































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { KEYS } from "@/plugins/i18n";
import { Auth, PaymentsListDialog, PlayerDetails } from "@/store/namespaces";
import UserDetailsComponent from "@/components/UserDetailsComponent.vue";
import ChangePasswordComponent from "@/components/ChangePasswordComponent.vue";
import PlayerDto from "@/dtos/PlayerDto";
import { nameof } from "ts-simple-nameof";
import TableComponent from "@/components/TableComponent.vue";
import { ShowDialogData } from "@/store/PlayerDialog.module";
import PlayerDetailsComponent from "@/components/PlayerDetailsComponent.vue";
import { playersService } from "@/rest/players.service";
import { CustomFilters } from "@/plugins/filters";
import GetPlayerDto from "@/dtos/GetPlayerDto";
import PaymentsListComponent from "@/components/PaymentsListComponent.vue";
import PaymentsListDialogComponent from "@/components/PaymentsListDialogComponent.vue";
import CheckExcelPlayerPaymentComponent from "@/components/CheckExcelPlayerPaymentComponent.vue";
import PlayerPaidExcelColumnSelectionComponent from "@/components/PlayerPaidExcelColumnSelectionComponent.vue";
import { GetGenderTextByNumberValue } from "@/dtos/Gender";
import ImportPlayersComponent from "@/components/ImportPlayersComponent.vue";
import { ExcelUtils } from "@/utils/ExcelUtils";
import ImportPaymentsComponent from "@/components/ImportPaymentsComponent.vue";

@Component({
  components: {
    ImportPaymentsComponent,
    ImportPlayersComponent,
    PlayerPaidExcelColumnSelectionComponent,
    CheckExcelPlayerPaymentComponent,
    PaymentsListDialogComponent,
    PaymentsListComponent,
    PlayerDetailsComponent,
    TableComponent,
    ChangePasswordComponent,
    UserDetailsComponent,
  },
})
export default class PlayersListComponent extends Vue {
  @Prop()
  public clubIdFilter!: string | null;

  @Auth.Getter
  public isTournamentManager!: boolean;

  @Auth.Getter
  public isDachverbandUser!: boolean;

  @Auth.Getter
  public isClubManager!: boolean;

  @PlayerDetails.Getter
  public isShowing!: boolean;
  public playersFiltered: GetPlayerDto[] = [];
  public playersAll: GetPlayerDto[] = [];

  public get canImportPlayers(): boolean {
    return this.isClubManager;
  }

  public get canCheckPlayersPaymentStatus(): boolean {
    return this.isDachverbandUser || this.isTournamentManager;
  }

  public get canImportPayments(): boolean {
    return this.isDachverbandUser;
  }

  @Ref("btnExcelUploadCheckPayments")
  public btnExcelUploadCheckPayments!: HTMLInputElement;

  @Ref("btnExcelUploadImportPlayers")
  public btnExcelUploadImportPlayers!: HTMLInputElement;

  @Ref("btnExcelUploadImportPayments")
  public btnExcelUploadImportPayments!: HTMLInputElement;

  public searchText = "";
  public onlyOedgvMembers = false;
  public onlyYouthMembers = false;

  public l_search = this.$t(KEYS.common.search).toString();
  public sGender = this.$t(KEYS.players.gender).toString();
  public l_oedgvMembersOnly = this.$t(
    KEYS.common.oedgv_members_only
  ).toString();
  public l_youthOnly = this.$t(KEYS.common.youth_members_only).toString();

  public sCheckPayments = this.$t(KEYS.payments.checkPayments).toString();
  public sImportPayments = this.$t(KEYS.payments.importPayments).toString();
  public sExportPlayers = this.$t(KEYS.players.exportPlayers).toString();
  public sDeletePlayer = this.$t(KEYS.players.deletePlayer).toString();
  public sImportPlayers = this.$t(KEYS.players.importPlayers).toString();

  public excelDataCheckPayments: (string | number)[][] | null = null;
  public excelDataImportPlayers: (string | number)[][] | null = null;
  public excelDataImportPayments: (string | number)[][] | null = null;

  @PaymentsListDialog.Mutation
  showPaymentsListDialog!: (playerId: string | null) => void;
  @PaymentsListDialog.Getter
  private isPaymentsListDialogShowing!: boolean;

  public fileCheckPaymentsChanged(files: FileList): void {
    ExcelUtils.readExcelFile(
      files,
      (result) => (this.excelDataCheckPayments = result)
    );
  }

  private removeHeaderRow(
    excelData: (string | number)[][]
  ): (string | number)[][] {
    const withoutHeader = [...excelData];
    withoutHeader.shift();

    return withoutHeader;
  }

  public fileImportPlayersChanged(files: FileList): void {
    ExcelUtils.readExcelFile(
      files,
      (result) => (this.excelDataImportPlayers = this.removeHeaderRow(result))
    );
  }

  public fileImportPaymentsChanged(files: FileList): void {
    ExcelUtils.readExcelFile(files, (result) => {
      this.excelDataImportPayments = result;
    });
  }

  public headers = [
    {
      text: this.$t(KEYS.players.firstName).toString(),
      value: nameof<GetPlayerDto>((p) => p.firstName),
      align: "center",
    },
    {
      text: this.$t(KEYS.players.lastName).toString(),
      value: nameof<GetPlayerDto>((p) => p.lastName),
      align: "center",
    },
    {
      text: this.sGender,
      value: nameof<GetPlayerDto>((p) => p.gender),
      formatter: (genderNumberValue: number): string =>
        GetGenderTextByNumberValue(genderNumberValue),
      align: "center",
    },
    {
      text: this.$t(KEYS.players.playerNumber).toString(),
      value: nameof<GetPlayerDto>((p) => p.playerNumber),
      formatter: CustomFilters.playerNumber,
      align: "center",
    },
    {
      text: this.$t(KEYS.players.pdgaNumber).toString(),
      value: nameof<GetPlayerDto>((p) => p.pdgaNumber),
      align: "center",
    },
    {
      text: this.$t(KEYS.players.primaryClub).toString(),
      value: nameof<GetPlayerDto>((p) => p.mainClubName),
      align: "center",
    },
    {
      text: this.$t(KEYS.players.oedgv_membership).toString(),
      value: nameof<GetPlayerDto>((p) => p.oedgvMembershipText),
      align: "center",
    },
    {
      text: this.$t(KEYS.players.youthPlayer).toString(),
      value: nameof<GetPlayerDto>((p) => p.isYouthPlayer_text),
      align: "center",
    },
    {
      text: "",
      value: "actions",
      align: "center",
    },
  ];

  public sPlayers = this.$t(KEYS.common.members).toString();

  @PlayerDetails.Mutation
  show!: (data: ShowDialogData) => void;

  @Auth.Getter
  private authenticatedClubId!: string;

  @Watch("searchText")
  private searchTextChange(): void {
    this.filter();
  }

  @Watch("onlyYouthMembers")
  private onlyYouthMembersChange() {
    this.filter();
  }

  @Watch("onlyOedgvMembers")
  private onlyOedgvMembersChange() {
    this.filter();
  }

  private filter(): void {
    const searchTextNormalized = this.searchText.trim().toLocaleLowerCase();
    let _playersFiltered = this.playersAll.slice();
    if (this.onlyOedgvMembers) {
      _playersFiltered = _playersFiltered.filter((p) => p.isActiveOedgvMember);
    }
    if (this.onlyYouthMembers) {
      _playersFiltered = _playersFiltered.filter((p) => p.isYouthPlayer);
    }

    if (searchTextNormalized !== "") {
      _playersFiltered = _playersFiltered.filter((p) => {
        for (let [attributeName, value] of Object.entries(p)) {
          if (!this.headers.some((header) => header.value === attributeName)) {
            continue;
          }

          let matches = (value ?? "")
            .toString()
            .toLowerCase()
            .startsWith(searchTextNormalized);

          if (matches) {
            return true;
          }
        }
        return false;
      });
    }

    this.playersFiltered = _playersFiltered;
  }

  get canAdd(): boolean {
    return this.isDachverbandUser || !!this?.authenticatedClubId;
  }

  public editClick(playerToEdit: PlayerDto): void {
    const clone = Object.assign({}, playerToEdit);
    this.show({ playerDto: clone, isShowing: true });
  }

  public showPaymentsClick(player: PlayerDto): void {
    this.showPaymentsListDialog(player.id);
  }

  @Watch("showPaymentsListDialog")
  public showPaymentsListDialogChange(newValue: boolean): void {
    if (!newValue) {
      this.loadData();
    }
  }

  public createClick(): void {
    this.show({ playerDto: null, isShowing: true });
  }

  async mounted(): Promise<void> {
    return await this.loadData();
  }

  private async loadData(): Promise<void> {
    this.playersAll = await playersService.getAll();
    if (this.clubIdFilter != null) {
      this.playersAll = this.playersAll.filter(
        (p) => p.mainClubId == this.clubIdFilter
      );
    }
    this.filter();
  }

  @Watch("isShowing")
  private async onIsShowingChange(newValue: boolean): Promise<void> {
    if (!newValue) {
      await this.loadData();
    }
  }

  @Watch("clubIdFilter")
  private async onClubIdFilterChange(newVal: string) {
    if (newVal) {
      await this.loadData();
    }
  }

  @Watch("isPaymentsListDialogShowing")
  private async isPaymentsListDialogShowingChange(newValue: boolean) {
    if (!newValue) {
      await this.loadData();
    }
  }

  public exportExcel(): void {
    if (!this.playersFiltered || this.playersFiltered.length == 0) {
      return;
    }
    const headerFieldDefinition = this.headers.filter(
      (header) => !!header.text
    );

    if (this.isDachverbandUser) {
      headerFieldDefinition.push({
        text: this.$t(KEYS.players.email).toString(),
        value: nameof<GetPlayerDto>((p) => p.email),
        align: "",
      });
    }

    const _rows = this.playersFiltered.map((player) => {
      const row: { [k: string]: string } = {};
      headerFieldDefinition.forEach((header) => {
        const dtoValue = player[header.value as keyof PlayerDto];
        if (header.formatter) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          row[header.text] = header.formatter(dtoValue);
        } else {
          row[header.text] = dtoValue?.toString() || "";
        }
      });
      return row;
    });

    ExcelUtils.exportExcel(_rows, "Mitglieder");
  }

  public async onDeletePlayer(player: PlayerDto): Promise<void> {
    await playersService.delete(player);
    await this.loadData();
  }

  public async onPlayersSucccessfullyImported(): Promise<void> {
    this.excelDataImportPlayers = null;
    await this.loadData();
  }

  public async onPaymentsSuccessfullyImported(): Promise<void> {
    this.excelDataImportPayments = null;
    await this.loadData();
  }
}
